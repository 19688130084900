import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import {
  motion,
  MotionValue,
  useScroll,
  useSpring,
  useTransform,
  useViewportScroll,
} from "framer-motion";
import "./OneStopShopMenu.css";
import { useTranslation } from "react-i18next";
import acabado from "../../../images/Menu/OneStopShop/OneStopShop_Acabado.jpg";
import mobiliario from "../../../images/Menu/OneStopShop/OneStopShop_Mobiliario.jpg";
import acabado2 from "../../../images/Menu/OneStopShop/OneStopShop_Acabado2.jpg";
import mobiliario2 from "../../../images/Menu/OneStopShop/OneStopShop_Mobiliario2.jpg";
import equipoTI from "../../../images/Menu/OneStopShop/OneStopShop_EquipoTI.jpg";
import officeas from "../../../images/Menu/OneStopShop/OneStopShop_OfficeAs.jpg";
import officeas2 from "../../../images/Menu/OneStopShop/OneStopShop_OfficeAs2.jpg";
import unSoloPunto from "../../../images/Menu/OneStopShop/OneStopShop_UnSoloPunto.jpg";
import unSoloPunto2 from "../../../images/Menu/OneStopShop/OneStopShop_UnSoloPunto2.jpg";
import lateral from "../../../images/Menu/OneStopShop/OneStopShop_Lateral.jpg";
import lateral2 from "../../../images/Menu/OneStopShop/OneStopShop_Lateral2.jpg";
import limpieza from "../../../images/Menu/OneStopShop/OneStopShop_Servicios.jpg";
import seguridad from "../../../images/Menu/OneStopShop/OneStopShop_Servicios2.jpg";
import ti from "../../../images/Menu/OneStopShop/OneStopShop_Servicios3.jpg";
import recepcion from "../../../images/Menu/OneStopShop/OneStopShop_Servicios4.jpg";
import importancia from "../../../images/Menu/OneStopShop/OneStopShop_Servicios5.jpg";

export default function OneStopShopMenu() {
  const { t: translate } = useTranslation();
  return (
    <>
      <section>
        <section className="oneStop_container">
          <div className="oneStop_fondo"></div>
          <motion.div
            className="oneStop_animated"
            // style={{ outlineOffset: miScale, outlineWidth: miScale2 }}
            whileHover={{
              transition: { duration: 0.3 },
            }}
          ></motion.div>

          <section className="oneShop-section">
            <div className="officeSolition">
              <h1 className="h1Titulo">OFFICE SOLUTIONS</h1>
              <div className="officeSolution_Container">
                <div className="oneH1">
                  <h1>BASIC BUILDOUT</h1>
                </div>
                <div className="officeText">
                  <p>{translate("one-stop-shop.base-buildout-text")}</p>
                </div>
              </div>

              <div className="plugPlay_Container">
                <div className="oneH1">
                  <h1>PLUG AND PLAY</h1>
                </div>

                <div className="plusColumn">
                  <div className="plusParrafo">
                    <p>{translate("one-stop-shop.plug-and-play")}</p>
                  </div>
                  <div className="playCol">
                    <div className="colUno">
                      <div className="plusImagen">
                        <img src={acabado} alt="" />
                      </div>
                      <div className="plusText">
                        <h2>{translate("one-stop-shop.acabados")}</h2>
                        <p>
                          {translate("one-stop-shop.plug-and-play-acabado")}
                        </p>
                      </div>
                    </div>
                    <div className="colDos">
                      <div className="plusImagen">
                        <img src={mobiliario} alt="" />
                      </div>
                      <div className="plusText">
                        <h2>{translate("one-stop-shop.mobiliario")}</h2>
                        <p>
                          {translate("one-stop-shop.plug-and-play-mobiliario")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="plus_Container">
                <div className="plusPlay_Container">
                  <div className="oneH1">
                    <h1 className="h1Plus">PLUG AND PLAY PLUS</h1>
                  </div>

                  <div className="plusColumn">
                    <div className="plusParrafo">
                      <p>{translate("one-stop-shop.plug-and-play-plus")}</p>
                    </div>
                  </div>
                </div>
                <div className="plusCol">
                  <div className="colUno">
                    <div className="plusImagen">
                      <img src={equipoTI} alt="" />
                    </div>
                    <div className="plusText">
                      <h2>{translate("one-stop-shop.ti")}</h2>
                      <p>
                        {translate("one-stop-shop.plug-and-play-plus-equipoti")}
                      </p>
                    </div>
                  </div>
                  <div className="colDos">
                    <div className="plusImagen">
                      <img src={acabado2} alt="" />
                    </div>
                    <div className="plusText">
                      <h2>{translate("one-stop-shop.acabados")}</h2>
                      <p>
                        {translate("one-stop-shop.plug-and-play-plus-acabado")}
                      </p>
                    </div>
                  </div>
                  <div className="colTres">
                    <div className="plusImagen">
                      <img src={mobiliario2} alt="" />
                    </div>
                    <div className="plusText">
                      <h2>{translate("one-stop-shop.mobiliario")}</h2>
                      <p>
                        {translate(
                          "one-stop-shop.plug-and-play-plus-mobiliario"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="oneShop-section">
            <div className="officeSolitionColor">
              <h1>OFFICE AS A SERVICE</h1>
              <div className="officeService_Container">
                <div className="OfficeAs">
                  <div className="parrafoAs">
                    <p>{translate("one-stop-shop.plug-and-play-plus-oaas")}</p>
                  </div>
                  <div className="ulOfficeAs">
                    <ul>
                      <li>
                        {" "}
                        {translate(
                          "one-stop-shop.plug-and-play-plus-oaas-1"
                        )}{" "}
                      </li>
                      <li>
                        {" "}
                        {translate(
                          "one-stop-shop.plug-and-play-plus-oaas-2"
                        )}{" "}
                      </li>
                      <li>
                        {" "}
                        {translate(
                          "one-stop-shop.plug-and-play-plus-oaas-3"
                        )}{" "}
                      </li>
                      <li>
                        {" "}
                        {translate(
                          "one-stop-shop.plug-and-play-plus-oaas-4"
                        )}{" "}
                      </li>
                      <li>
                        {" "}
                        {translate(
                          "one-stop-shop.plug-and-play-plus-oaas-5"
                        )}{" "}
                      </li>
                      <li>
                        {" "}
                        {translate(
                          "one-stop-shop.plug-and-play-plus-oaas-6"
                        )}{" "}
                      </li>
                      <li>
                        {" "}
                        {translate(
                          "one-stop-shop.plug-and-play-plus-oaas-7"
                        )}{" "}
                      </li>
                      <li>
                        {" "}
                        {translate(
                          "one-stop-shop.plug-and-play-plus-oaas-8"
                        )}{" "}
                      </li>
                      <li>
                        {" "}
                        {translate(
                          "one-stop-shop.plug-and-play-plus-oaas-9"
                        )}{" "}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="officeAs_image">
                  <div className="plusImagen">
                    <img src={officeas} alt="" />
                  </div>
                  <div className="plusImagen">
                    <img src={officeas2} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="officeSolition">
              <h1>{translate("one-stop-shop.punto-contacto")}</h1>
              <div className="officeAs_Container">
                <div className="officeAs_image">
                  <div className="plusImagen">
                    <img src={unSoloPunto} alt="" />
                  </div>
                  <div className="plusImagen">
                    <img src={unSoloPunto2} alt="" />
                  </div>
                </div>
                <div className="OfficeAs">
                  <div className="parrafoAs">
                    <p className="primerParra">
                      {translate("one-stop-shop.un-solo-punto-1")}
                    </p>
                    <p>{translate("one-stop-shop.un-solo-punto-2")}</p>
                    <p>{translate("one-stop-shop.un-solo-punto-3")}</p>
                    <p>{translate("one-stop-shop.un-solo-punto-4")}</p>
                    <p>{translate("one-stop-shop.un-solo-punto-5")}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="officeSolitionColor">
              <h1>LATERAL</h1>
              <div className="officeService_Container">
                <div className="OfficeAs">
                  <div className="parrafoAs">
                    <p>{translate("one-stop-shop.lateral")}</p>
                  </div>
                  <div className="ulOfficeAs">
                    <ul>
                      <li>{translate("one-stop-shop.lateral-1")}</li>
                      <li>{translate("one-stop-shop.lateral-2")}</li>
                      <li>{translate("one-stop-shop.lateral-3")}</li>
                      <li>{translate("one-stop-shop.lateral-4")}</li>
                      <li>{translate("one-stop-shop.lateral-5")}</li>
                      <li>{translate("one-stop-shop.lateral-6")}</li>
                      <li>{translate("one-stop-shop.lateral-7")}</li>
                      <li>{translate("one-stop-shop.lateral-8")}</li>
                      <li>{translate("one-stop-shop.lateral-9")}</li>
                      <li>{translate("one-stop-shop.lateral-10")}</li>
                      <li>{translate("one-stop-shop.lateral-11")}</li>
                    </ul>
                  </div>
                </div>
                <div className="officeAs_image">
                  <div className="flexSpace">
                    <h1>FLEX SPACE</h1>
                  </div>
                  <div className="plusImagen">
                    <img src={lateral} alt="" />
                  </div>
                  <div className="plusImagen">
                    <img src={lateral2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="oneShop-section">
            <div className="officeSolition">
              <h1>{translate("one-stop-shop.additional-service")}</h1>
              <div className="serviceAdicio_Container">
                <div className="officeAs_image">
                  <h2>{translate("one-stop-shop.limpieza")}</h2>
                  <div className="plusImagen">
                    <img src={limpieza} alt="" />
                  </div>
                </div>
                <div className="adicionalColum">
                  <div className="ulOfficeAs">
                    <ul>
                      <li>
                        {translate("one-stop-shop.servicios-adicionales-1")}
                      </li>
                      <li>
                        {translate("one-stop-shop.servicios-adicionales-2")}
                      </li>
                      <li>
                        {translate("one-stop-shop.servicios-adicionales-3")}
                      </li>
                      <li>
                        {translate("one-stop-shop.servicios-adicionales-4")}
                      </li>
                      <li>
                        {translate("one-stop-shop.servicios-adicionales-5")}
                      </li>
                      <li>
                        {translate("one-stop-shop.servicios-adicionales-6")}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="serviceAdicio_Container">
                <div className="officeAs_image">
                  <h2>{translate("one-stop-shop.seguridad")}</h2>
                  <div className="plusImagen">
                    <img src={seguridad} alt="" />
                  </div>
                </div>
                <div className="adicionalColum">
                  <div className="ulOfficeAs">
                    <ul>
                      <li>{translate("one-stop-shop.seguridad-1")}</li>
                      <li>{translate("one-stop-shop.seguridad-2")}</li>
                      <li>{translate("one-stop-shop.seguridad-3")}</li>
                      <li>{translate("one-stop-shop.seguridad-4")}</li>
                      <li>{translate("one-stop-shop.seguridad-5")}</li>
                      <li>{translate("one-stop-shop.seguridad-6")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="serviceAdicio_Container">
                <div className="officeAs_image">
                  <h2>TI</h2>
                  <div className="plusImagen">
                    <img src={ti} alt="" />
                  </div>
                </div>
                <div className="adicionalColum">
                  <div className="ulOfficeAs">
                    <ul>
                      <li>{translate("one-stop-shop.ti-1")}</li>
                      <li>{translate("one-stop-shop.ti-2")}</li>
                      <li>{translate("one-stop-shop.ti-3")}</li>
                      <li>{translate("one-stop-shop.ti-4")}</li>
                      <li>{translate("one-stop-shop.ti-5")}</li>
                      <li>{translate("one-stop-shop.ti-6")}</li>
                      <li>{translate("one-stop-shop.ti-7")}</li>
                      <li>{translate("one-stop-shop.ti-8")}</li>
                      <li>{translate("one-stop-shop.ti-9")}</li>
                      {translate("one-stop-shop.ti-10") !== "" && (
                        <li>{translate("one-stop-shop.ti-10")}</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="serviceAdicio_Container">
                <div className="officeAs_image">
                  <h2>{translate("one-stop-shop.recepcion")}</h2>
                  <div className="plusImagen">
                    <img src={recepcion} alt="" />
                  </div>
                </div>
                <div className="adicionalColum">
                  <div className="ulOfficeAs">
                    <ul>
                      <li>{translate("one-stop-shop.recepcion-1")}</li>
                      <li>{translate("one-stop-shop.recepcion-2")}</li>
                      <li>{translate("one-stop-shop.recepcion-3")}</li>
                      <li>{translate("one-stop-shop.recepcion-5")}</li>
                      <li>{translate("one-stop-shop.recepcion-6")}</li>
                      <li>{translate("one-stop-shop.recepcion-7")}</li>
                      <li>{translate("one-stop-shop.recepcion-8")}</li>
                      <li>{translate("one-stop-shop.recepcion-9")}</li>
                      <li>{translate("one-stop-shop.recepcion-10")}</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="serviceAdicioSin_Container">
                <div className="officeAs_image">
                  <h2>{translate("one-stop-shop.logistica")}</h2>
                  <div className="plusImagen">
                    <img src={importancia} alt="" />
                  </div>
                </div>
                <div className="adicionalColum">
                  <div className="ulOfficeAs">
                    <ul>
                      <li>
                        {translate("one-stop-shop.importacion-logistica-1")}
                      </li>
                      {translate("one-stop-shop.importacion-logistica-1-5") !==
                        "" && (
                        <li>
                          {translate("one-stop-shop.importacion-logistica-1-5")}
                        </li>
                      )}

                      <li>
                        {translate("one-stop-shop.importacion-logistica-2")}
                      </li>
                      <li>
                        {translate("one-stop-shop.importacion-logistica-3")}
                      </li>
                      <li>
                        {translate("one-stop-shop.importacion-logistica-4")}
                      </li>
                      <li>
                        {translate("one-stop-shop.importacion-logistica-5")}
                      </li>
                      <li>
                        {translate("one-stop-shop.importacion-logistica-6")}
                      </li>
                      <li>
                        {translate("one-stop-shop.importacion-logistica-7")}
                      </li>
                      <li>
                        {translate("one-stop-shop.importacion-logistica-8")}
                      </li>
                      <li>
                        {translate("one-stop-shop.importacion-logistica-9")}
                      </li>
                      <li>
                        {translate("one-stop-shop.importacion-logistica-10")}
                      </li>
                      <li>
                        {translate("one-stop-shop.importacion-logistica-11")}
                      </li>
                      <li>
                        {translate("one-stop-shop.importacion-logistica-12")}
                      </li>
                      <li>
                        {translate("one-stop-shop.importacion-logistica-13")}
                      </li>
                      <li>
                        {translate("one-stop-shop.importacion-logistica-14")}
                      </li>
                      {/* prueba */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  );
}
