import React from "react";
import ReactPlayer from "react-player/lazy";

import "./Player.css";

const Player = ({ url }: any) => {
  return (
    <ReactPlayer
      // className="videoPlayer"
      url={url}
      width="100%"
      height="100%"
      controls
      playing
      // loop
      muted
      //   onStart={handleOnYoutubePlayStart}
      //   onEnded={handleOnYoutubePlayEnd}
      config={{
        file: {
          attributes: {
            preload: "auto",
          },
        },
      }}
    />
  );
};

export default Player;
