import react, { Dispatch } from "react";

interface icontextprops {
  state: any;
  dispatch: any;
}

const Customcontext = react.createContext({} as icontextprops);

export function usecustomcontext() {
  return react.useContext(Customcontext);
}

export default Customcontext;
