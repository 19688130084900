import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../images/Logo_Ultrapark-2.png";
import logoBlack from "../../images/Menu/LogoUltraparkBlack.png";
import "./Navbar.css";

export interface IMenuNavBar {
  setIsMenuOpen: Function;
  isMenuOpen?: boolean;
  wasSubMenuClicked?: boolean;
  onClickHandled?: Function;
}

// const Header: React.FC<IDatePickerHeaderProps> = (props) => {
const NavbarAnimated: React.FC<IMenuNavBar> = (props) => {
  // setting mobile nav
  const handleClick = () => {
    if (props.onClickHandled) {
      props.onClickHandled && props.onClickHandled();
    } else {
      props.setIsMenuOpen(!props.isMenuOpen);
    }
  };

  // close menu  on click
  const closeMenu = () => props.setIsMenuOpen(false);

  // set change color when scrolling
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 90) {
      // greater tgab 90 cuz that is the header size.
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor); // everything scroll change run the assgin function. i.e changeColor
  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.03, delayChildren: 0.05 * i },
    }),
  };

  debugger;
  return (
    <div className="header">
      <nav className={props.isMenuOpen ? "navbar header-bg" : "navbar"}>
        <a href="/" className="logo-ultra">
          {props.isMenuOpen ? (
            <img src={logoBlack} alt="logoBlack" />
          ) : (
            <img src={logo} alt="logo" />
          )}
        </a>
        <AnimatePresence presenceAffectsLayout>
          {!props.wasSubMenuClicked && (
            <motion.div
              className="hamburger"
              onClick={handleClick}
              exit={{ opacity: 0, transition: { duration: 0.5 } }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.5 } }}
              whileHover={{ scale: 1.2, transition: { duration: 0.3 } }}
            >
              {props.isMenuOpen ? (
                <FaTimes size={30} style={{ color: "black" }} />
              ) : (
                <FaBars size={30} style={{ color: "white" }} />
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </nav>
    </div>
  );
};

export default NavbarAnimated;
