import { motion, useScroll, useTransform } from "framer-motion";
import pic2 from "../../../images/Services2.png";
import pic1 from "../../../images/Services1.png";
import "./WhyUs.css";
import { useWindowWidth } from "../../utils/customHook/useWindowWidth";
import { useTranslation } from "react-i18next";

const MOBILE_WIDTH = 420;
const WhyUS = () => {
  const { scrollYProgress } = useScroll();
  // const y1 = useTransform(scrollYProgress, [0, 0.5, 1], [0, 50, 100]); // source, [initial value, lmite value(threahold, final value)],  the relationshi between input and output REMEMBER
  const x1 = useTransform(scrollYProgress, [0.2, 0.5], [-1900 * 1.1, 0]); // source, [initial value, lmite value(threahold, final value)],  the relationshi between input and output REMEMBER
  const scale = useTransform(scrollYProgress, [0.275, 0.5], [0, 1]); // source, [initial value, lmite value(threahold, final value)],  the relationshi between input and output REMEMBER
  const rotate = useTransform(scrollYProgress, [0.275, 0.5], [0, 360]); // source, [initial value, lmite value(threahold, final value)],  the relationshi between input and output REMEMBER

  const firstCardStyle = { x: x1 };

  const x2 = useTransform(scrollYProgress, [0.2, 0.5], [1900 * 1.1, 0]); // source, [initial value, lmite value(threahold, final value)],  the relationshi between input and output REMEMBER
  const scale2 = useTransform(scrollYProgress, [0.275, 0.6], [0, 1]); // source, [initial value, lmite value(threahold, final value)],  the relationshi between input and output REMEMBER
  const rotate2 = useTransform(scrollYProgress, [0.275, 0.5], [0, 360]); // source, [initial value, lmite value(threahold, final value)],  the relationshi between input and output REMEMBER

  const miScale2 = useTransform(scrollYProgress, [0.9, 1], ["300px", "0px"]); // source, [initial value, lmite value(threahold, final value)],  the relationshi between input and output REMEMBER

  const miScale = useTransform(scrollYProgress, [0.9, 1], ["0px", "300px"]); // source, [initial value, lmite value(threahold, final value)],  the relationshi between input and output REMEMBER

  const secondCardStyle = { x: x2 };

  const padding = useTransform(scrollYProgress, [0.6, 0.75], ["60px", "0px"]); // source, [initial value, lmite value(threahold, final value)],  the relationshi between input and output REMEMBER

  const windowWidth = useWindowWidth();

  const stylesTitle =
    windowWidth > MOBILE_WIDTH ? { gridRowStart: "span 2" } : { bottom: "0px" };

  const { t: translate } = useTranslation();

  return (
    <section>
      <div className="whyContainer">
        <div className="whyColumn" style={stylesTitle}>
          <p className="sh1">{translate("why-us-section.why-title")}</p>
        </div>
        <div className="whyColumn">
          <h3 className="columIzq">
            {translate("why-us-section.why-title-1")}
          </h3>
          <div className="column-content-Izqui">
            {translate("why-us-section.why-parag-1")}
          </div>
        </div>
        <div className="whyColumn">
          <h3 className="columIzq">
            {translate("why-us-section.why-title-2")}
          </h3>
          <div className="column-content-Izqui">
            {translate("why-us-section.why-parag-2")}
          </div>
        </div>
        <div className="whyColumn">
          <h3 className="columIzq">
            {translate("why-us-section.why-title-3")}
          </h3>
          <div className="column-content-Izqui">
            {translate("why-us-section.why-parag-3")}
          </div>
        </div>

        <div className="whyColumn">
          <h3 className="columIzq">
            {translate("why-us-section.why-title-4")}
          </h3>
          <div className="column-content-Izqui">
            {translate("why-us-section.why-parag-4")}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUS;
