import React, {
  useCallback,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  AnimatePresence,
  motion,
  MotionValue,
  useScroll,
  useSpring,
  useTransform,
  useViewportScroll,
} from "framer-motion";

import campusDialogHero from "../../../../images/Menu/Campus/UPI/Campus_Screen_UPI_Hero.jpg";
import Fondo from "../../../../images/Menu/Campus/UPEscazu/Campus_Screen_UPEsca_Fondo.jpg";
import imagen1 from "../../../../images/Menu/Campus/UPEscazu/Campus_Screen_UPEsca_Imagen1.jpg";
import imagen2 from "../../../../images/Menu/Campus/UPEscazu/Campus_Screen_UPEsca_imagen2.jpg";
import imagen3 from "../../../../images/Menu/Campus/UPEscazu/Campus_Screen_UPEsca_imagen3.jpg";
import imagen4 from "../../../../images/Menu/Campus/UPEscazu/Campus_Screen_UPEsca_imagen4.jpg";
import imagen5 from "../../../../images/Menu/Campus/UPEscazu/Campus_Screen_UPEsca_imagen5.jpg";
import imagen6 from "../../../../images/Menu/Campus/UPEscazu/Campus_Screen_UPEsca_imagen6.jpg";

import imagen7 from "../../../../images/Menu/Campus/UPEscazu/Campus_Screen_UPEsca_imagen7.jpg";
import videoUpEsc from "../../../../images/Menu/Campus/Videos/UltraparkEscazu.mp4";

import "./CampusUltraEsca.css";
import { FaTimes } from "react-icons/fa";
import {
  CampusActionType,
  campusMenuReducer,
} from "../../../../reducer/CampusReducer";
import { usecustomcontext } from "../../../../reducer/CustomContext";
import Player from "../../../../components/player/Player";
import { useTranslation } from "react-i18next";

export interface ICampusScreen {
  setWasCampusSelected?: Function;
  setCampusSelected?: Function;
}
export const CampusUltraEsca: React.FC<ICampusScreen> = (
  props: ICampusScreen
) => {
  const { setWasCampusSelected, setCampusSelected } = props;
  const { state, dispatch } = usecustomcontext();

  const { t: translate } = useTranslation();

  const handleClick = useCallback(() => {
    setWasCampusSelected && setWasCampusSelected(false);
    setCampusSelected && setCampusSelected(undefined);
    dispatch({ type: CampusActionType.CHANGESUBOPTION, payload: undefined });
  }, []);

  return (
    <>
      <section className="campusScreenOverlay">
        <AnimatePresence presenceAffectsLayout>
          <motion.div
            className="dialogClose"
            onClick={handleClick}
            exit={{ opacity: 0, transition: { duration: 0.5 } }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            whileHover={{ scale: 1.2, transition: { duration: 0.3 } }}
          >
            <FaTimes size={35} />

            {/* <FaBars size={35} style={{ color: "white" }} /> */}
          </motion.div>
        </AnimatePresence>
        <section className="campusScreenDialogContainer">
          <section className="campusScreenDialogHero">
            <Player url={videoUpEsc} autostart />
            <h2 className="video-title-esc">UltraparK ESCAZÚ</h2>
          </section>
          <section className="campusScreenContent">
            <div className="campusScreen_Fondo">
              <img src={Fondo} alt="" />
            </div>

            <div className="campusScreenColum">
              <div className="campusScreenColumUno">
                <img src={imagen1} alt="" />
              </div>
              <div className="campusScreenColumDos">
                <img src={imagen2} alt="" />
              </div>
            </div>
            <div className="campusScreenColum">
              <div className="campusScreenColumUno">
                <img src={imagen3} alt="" />
              </div>
              <div className="campusScreenColumDos">
                <img src={imagen4} alt="" />
              </div>
            </div>
            <div className="campusScreenColum">
              <div className="campusScreenColumUno">
                <img src={imagen5} alt="" />
              </div>
              <div className="campusScreenColumDos">
                <img src={imagen6} alt="" />
              </div>
            </div>
            <div className="campusScreenColum campus-screen-last">
              <div className="campusScreenColumUnoUbi">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7859.956126974665!2d-84.13263000000002!3d9.935783!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa0fd6d2912bb0d%3A0x6c1fafe26254a359!2sUltrapark%20Escaz%C3%BA!5e0!3m2!1sen!2scr!4v1698896078694!5m2!1sen!2scr"
                  width="100%"
                  height="450"
                  style={{ border: "0" }}
                  loading="lazy"
                ></iframe>
              </div>
              <div className="campusScreenColumDos address">
                <h2>{translate("campus-menu.campus-location")}</h2>
                <p>Escazú, San Rafael, San José </p>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  );
};
