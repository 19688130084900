import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import {
  motion,
  MotionValue,
  useScroll,
  useSpring,
  useTransform,
  useViewportScroll,
} from "framer-motion";
import MisionIcon from "../../../images/sections/ultrapark/iconoMision.svg";
import VisionIcon from "../../../images/sections/ultrapark/visionIcon.svg";
import "./UltraParkMenu.css";
import { useTranslation } from "react-i18next";

export default function UltraParkMenu() {
  const { t: translate } = useTranslation();
  return (
    <>
      <section>
        <section className="ultra_container">
          <div className="ultra_fondo"></div>
          <motion.div
            className="ultra_animated"
            // style={{ outlineOffset: miScale, outlineWidth: miScale2 }}
            whileHover={{
              transition: { duration: 0.3 },
            }}
          ></motion.div>
        </section>
        {/* historia section ultra */}
        <section className="history">
          <div className="historyContainer">
            <h3>{translate("ultrapark.historia-title")}</h3>

            <div className="historyContent">
              <p>{translate("ultrapark.historia-1")}</p>
              <p>{translate("ultrapark.historia-2")}</p>
              <p>{translate("ultrapark.historia-3")}</p>
            </div>
          </div>
        </section>
        <section className="mission">
          <div className="missionContainer">
            <div className="missionColumn">
              <img style={{ marginTop: "-10px" }} src={MisionIcon} alt="" />
              {/* le quite el margen de -20px al h3 de mision y le puse 20px */}
              <h3 style={{ marginTop: "20px" }}>Misión</h3>
              <div className="missionColumn-content">
                {translate("ultrapark.mision")}
              </div>
            </div>
            <div className="misionGradient" />
            <div className="visionGradient" />
            <div id="visionDiv" className="missionColumn">
              <img src={MisionIcon} alt="" />
              <h3>Visión</h3>
              <div className="missionColumn-content">
                {translate("ultrapark.vision")}
              </div>
            </div>
          </div>
        </section>
        <section className="valores">
          <div className="valores-container">
            <div className="valores-column">
              <p className="sh1">
                {translate("ultrapark.virtudes-valores-title")}
              </p>
            </div>
            <div className="valores-description">
              <p>{translate("ultrapark.virtudes-valores-1")}</p>
              <p>{translate("ultrapark.virtudes-valores-2")}</p>
            </div>
          </div>

          <div className=" valores-grid-container">
            <div className="valores-grid-items-row">
              <p className="valores-medium-item">
                {translate("ultrapark.valores-1")}
              </p>
              <p>{translate("ultrapark.valores-2")}</p>
              <p>{translate("ultrapark.valores-3")}</p>
              <p className="valores-medium-item">
                {translate("ultrapark.valores-1")}
              </p>
              <p>{translate("ultrapark.valores-2")}</p>
              <p>{translate("ultrapark.valores-3")}</p>
            </div>
            <div className="valores-grid-items-row">
              <p className="valores-medium-spacing-item">
                {translate("ultrapark.valores-4")}
              </p>
              <p className="valores-small-item">
                {translate("ultrapark.valores-5")}
              </p>
              <p className="valores-medium-spacing-item">
                {translate("ultrapark.valores-6")}
              </p>
              <p className="valores-medium-spacing-item">
                {translate("ultrapark.valores-4")}
              </p>
              <p className="valores-small-item">
                {translate("ultrapark.valores-5")}
              </p>
              <p className="valores-medium-spacing-item">
                {translate("ultrapark.valores-6")}
              </p>
            </div>
            <div className="valores-grid-items-row">
              <p className="valores-medium-item">
                {translate("ultrapark.valores-7")}
              </p>
              <p>{translate("ultrapark.valores-8")}</p>
              <p className="valores-medium-item">
                {translate("ultrapark.valores-9")}
              </p>
              <p className="valores-medium-item">
                {translate("ultrapark.valores-7")}
              </p>
              <p>{translate("ultrapark.valores-8")}</p>
              <p className="valores-medium-item">
                {translate("ultrapark.valores-9")}
              </p>
            </div>
            <div className="valores-grid-items-row">
              <p className="valores-medium-spacing-item">
                {translate("ultrapark.valores-10")}
              </p>
              <p className="valores-small-item">
                {translate("ultrapark.valores-11")}
              </p>
              <p className="valores-medium-spacing-item">
                {translate("ultrapark.valores-12")}
              </p>
              <p className="valores-medium-spacing-item">
                {translate("ultrapark.valores-10")}
              </p>
              <p className="valores-small-item">
                {translate("ultrapark.valores-11")}
              </p>
              <p className="valores-medium-spacing-item">
                {translate("ultrapark.valores-12")}
              </p>
            </div>
            <div className="valores-grid-items-row">
              <p>{translate("ultrapark.valores-13")}</p>
              <p className="valores-medium-item">
                {translate("ultrapark.valores-14")}
              </p>
              <p>{translate("ultrapark.valores-15")}</p>
              <p>{translate("ultrapark.valores-13")}</p>
              <p className="valores-medium-item">
                {translate("ultrapark.valores-14")}
              </p>
              <p>{translate("ultrapark.valores-15")}</p>
            </div>
          </div>

          <div className="valores-grid-container-mobile-wrapper">
            <div className="valores-grid-container-mobile">
              <div className="valores-grid-items-row">
                <p className="valores-letra-mobile">
                  {translate("ultrapark.valores-1")}
                </p>
                <p className="valores-negrita-mobile">
                  {translate("ultrapark.valores-2")}
                </p>
              </div>
              <div
                style={{ marginLeft: "5vw" }}
                className="valores-grid-items-row"
              >
                <p className="valores-negrita-mobile">
                  {translate("ultrapark.valores-4")}
                </p>
                <p className="valores-letra-mobile">
                  {translate("ultrapark.valores-3")}
                </p>
              </div>
              <div className="valores-grid-items-row">
                <p className="valores-letra-mobile">
                  {translate("ultrapark.valores-5")}
                </p>
                <p className="valores-negrita-mobile">
                  {translate("ultrapark.valores-6")}
                </p>
              </div>

              <div
                style={{ marginLeft: "8vw" }}
                className="valores-grid-items-row"
              >
                <p className="valores-letra-mobile">
                  {translate("ultrapark.valores-7")}
                </p>
                <p className="valores-negrita-mobile">
                  {translate("ultrapark.valores-8")}
                </p>
              </div>

              <div
                style={{ marginLeft: "4svw" }}
                className="valores-grid-items-row"
              >
                <p className="valores-negrita-mobile">
                  {translate("ultrapark.valores-9")}
                </p>
                <p className="valores-letra-mobile">
                  {translate("ultrapark.valores-10")}
                </p>
              </div>

              <div className="valores-grid-items-row">
                <p className="valores-letra-mobile">
                  {translate("ultrapark.valores-11")}
                </p>
                <p className="valores-negrita-mobile">
                  {translate("ultrapark.valores-12")}
                </p>
              </div>

              <div
                style={{ marginLeft: "6vw" }}
                className="valores-grid-items-row"
              >
                <p className="valores-negrita-mobile">
                  {translate("ultrapark.valores-13")}
                </p>
                <p className="valores-negrita-mobile">
                  {translate("ultrapark.valores-15")}
                </p>
              </div>

              <div className="valores-grid-items-row">
                <p className="valores-letra-mobile">
                  {translate("ultrapark.valores-14")}
                </p>
                <p className="valores-negrita-mobile">
                  {translate("ultrapark.valores-15")}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="ultrapark-preFooter">
          <div className="ultrapark-preFooter-description">
            <p>{translate("ultrapark.prefooter-1")}</p>
            <p>{translate("ultrapark.prefooter-2")}</p>
            <p>{translate("ultrapark.prefooter-3")}</p>
            <p>{translate("ultrapark.prefooter-4")}</p>
          </div>
        </section>
      </section>
    </>
  );
}
