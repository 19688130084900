import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import {
  motion,
  MotionValue,
  useScroll,
  useSpring,
  useTransform,
  useViewportScroll,
} from "framer-motion";
import "./CostaRicaMenu.css";
import picAmbi from "../../../images/Menu/costarica/CostaRica_Ambiente.jpg";
import picHero from "../../../images/Menu/costarica/CostaRica_Hero.jpg";
import picHisto from "../../../images/Menu/costarica/CostaRica_Historia.jpg";
import picTale from "../../../images/Menu/costarica/CostaRica_Talento.jpg";
import { useTranslation } from "react-i18next";

export default function CostaRicaMenu() {
  const { t: translate } = useTranslation();

  return (
    <>
      <section>
        <div className="grient_Historia">
          <section className="costaRica_container">
            <div className="costarica_fondo"></div>
            <motion.div
              className="costaRica_animated"
              // style={{ outlineOffset: miScale, outlineWidth: miScale2 }}
              whileHover={{
                transition: { duration: 0.3 },
              }}
            ></motion.div>
          </section>
          {/* historia section ultra */}
          <section className="crHistory">
            <div className="crImage-effect">
              <img src={picHisto} alt="" />
            </div>
            <div className="crHistoryContainer">
              <div className="CostaricaText">
                <h4 className="CrH4">
                  {translate("cr-menu.cr-history-title")}
                </h4>
                <p>{translate("cr-menu.cr-history-extra-parag")}</p>

                <p>{translate("cr-menu.cr-history-first-parag")}</p>
                <p>{translate("cr-menu.cr-history-second-parag")}</p>
                <p>{translate("cr-menu.cr-history-third-parag")}</p>
                <br></br>
              </div>
            </div>
          </section>
        </div>

        <section className="crAmbiente">
          <div className="crAmbienteContainer">
            <div className="ambienteTexto">
              <h4>{translate("cr-menu.cr-env-title")}</h4>
              <p>{translate("cr-menu.cr-env-first-parag")}</p>

              <p>{translate("cr-menu.cr-env-second-parag")}</p>
              <p>{translate("cr-menu.cr-env-third-parag")}</p>
              <p>{translate("cr-menu.cr-env-extra-parag-1")}</p>
              <p>{translate("cr-menu.cr-env-extra-parag-2")}</p>
              <p>{translate("cr-menu.cr-env-extra-parag-3")}</p>
              <br></br>
            </div>
          </div>
          <div className="crImage-effect">
            <img src={picAmbi} alt="" />
          </div>
        </section>
        <section className="crTalento">
          <div className="crImage-effect">
            <img src={picTale} alt="" />
          </div>
          <div className="crTalentoContainer">
            <div className="talentoText">
              <h4>{translate("cr-menu.cr-talen-title")}</h4>
              <p>{translate("cr-menu.cr-talen-parag-1")}</p>
              <p>{translate("cr-menu.cr-talen-parag-2")}</p>
              <p>{translate("cr-menu.cr-talen-parag-3")}</p>
              <p>{translate("cr-menu.cr-talen-parag-4")}</p>
              <p>{translate("cr-menu.cr-talen-parag-5")}</p>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
