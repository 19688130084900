import React, { useState, useEffect, useReducer, ReactNode, memo } from "react";
import AppMain from "../../../App";

import { SplashTree } from "../splashTreeAnimated/SplashTree";
import react from "react";
import { campusMenuReducer } from "../../../reducer/CampusReducer";
import Customcontext from "../../../reducer/CustomContext";
import {
  BrowserRouter,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import UltraParkMenuDialog from "../../../modules/menu/ultrapark/UltraParkMenuDialog";

const delaySplash = 4; // giving in seconds

interface IMainPage {
  component?: ReactNode;
}

const MainPage: React.FC<IMainPage> = (props: IMainPage) => {
  const [isLoading, setIsLoading] = useState(() => {
    return true;
  });

  useEffect(() => {
    setTimeout(() => setIsLoading(false), delaySplash * 1000);
    window.history.replaceState({}, document.title); // remplace the state. reset for the menu.
  }, []);

  return (
    <>
      {isLoading ? <SplashTree delaySplash={delaySplash} /> : props.component}
    </>
  );
};

export default memo(MainPage);
