import React, { useState, useEffect, useReducer, memo } from "react";
import {
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
} from "react-router-dom";
import UltraParkMenuDialog from "./modules/menu/ultrapark/UltraParkMenuDialog";
import { campusMenuReducer } from "./reducer/CampusReducer";
import AppMain from "./App";
import Customcontext from "./reducer/CustomContext";
import OneStopShopMainDialog from "./modules/oneStopShop/main/OneStopShopMainDialog";
import CampusMenuDialog from "./modules/menu/campus/CampusMenuDialog";
import SOSMenuDialog from "./modules/menu/sostenibilidad/SOSMenuDialog";
import CostaRicaDialog from "./modules/menu/costaRica/CostaRicaDialog";

const delaySplash = 4; // giving in seconds

const WrappedMainComponent: React.FC = () => {
  const [state, dispatch] = useReducer(campusMenuReducer, {});

  const providerstate = {
    state,
    dispatch,
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <AppMain />,
    },
    // other pages....
    {
      path: "/ultrapark",
      element: <UltraParkMenuDialog />,
    },
    {
      path: "/one-stop-shop",
      element: <OneStopShopMainDialog />,
    },
    {
      path: "/campus",
      element: <CampusMenuDialog />,
    },
    {
      path: "/sos",
      element: <SOSMenuDialog />,
    },
    {
      path: "/costarica",
      element: <CostaRicaDialog />,
    },
  ]);
  // const component = (
  //   <Customcontext.Provider value={providerstate}>
  //     <RouterProvider router={router} />
  //   </Customcontext.Provider>
  // );

  return (
    <>
      <Customcontext.Provider value={providerstate}>
        <RouterProvider router={router} />
      </Customcontext.Provider>
    </>
  );
};

export default memo(WrappedMainComponent);
