import "./PreFooter.css";
import prefooterImage from "../../images/footer/Ultrapark-prefooter.jpg";

const PreFooter = () => {
  return (
    <div className="pre-footer">
      <div className="preFootercontainer">
        <img src={prefooterImage} alt="" />
      </div>
    </div>
  );
};

export default PreFooter;
