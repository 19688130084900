import React, { useReducer } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AppMain from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import MainPage from "./components/animations/mainPage/MainPage";
import "./i18n/config";
import UltraParkMenuDialog from "./modules/menu/ultrapark/UltraParkMenuDialog";
import Customcontext from "./reducer/CustomContext";
import { campusMenuReducer } from "./reducer/CampusReducer";
import WrappedMainComponent from "./WrappedMainComponent";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<MainPage component={<WrappedMainComponent />} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
