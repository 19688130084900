// An enum with all the types of actions to use in our reducer
export enum CampusActionType {
  CHANGESUBOPTION = "CHANGESUBOPTION",
}

interface CampusAction {
  type: CampusActionType;
  payload?: string;
}

// An interface for our state
interface SubMenuSelectionState {
  subMenuSelection?: string;
}

// Our reducer function that uses a switch statement to handle our actions
export function campusMenuReducer(
  state: SubMenuSelectionState,
  action: CampusAction
) {
  const { type, payload } = action;
  switch (type) {
    case CampusActionType.CHANGESUBOPTION:
      return {
        ...state,
        subMenuSelection: payload,
      };
    default:
      return state;
  }
}
