import React, {
  useCallback,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  AnimatePresence,
  motion,
  MotionValue,
  useScroll,
  useSpring,
  useTransform,
  useViewportScroll,
} from "framer-motion";

import picUltra1 from "../../../images/homeSlider/Ultrapark-arbol.jpg";

// import img1 from "../../images/homeSlider/Ultrapark-arbol.jpg";
import picUltraLag from "../../../images/Menu/Campus/Campus_ultrapark_lag.jpg";
import picUltra2 from "../../../images/Menu/Campus/Campus_ultrapark2.jpg";
import picUltraEsca from "../../../images/Menu/Campus/Campus_ultrapark_escazu.jpg";
import iconWell from "../../../images/Menu/Campus/Campus_wellness_icon.svg";
import picWell from "../../../images/Menu/Campus/Campus_wellness.jpg";
import iconall from "../../../images/Menu/Campus/Campus_all_in_one_icon.svg";
import picall from "../../../images/Menu/Campus/Campus_all in one.jpg";
import iconazo from "../../../images/Menu/Campus/Campus_azotea_icon.svg";
import picazo from "../../../images/Menu/Campus/Campus_azotea.jpg";
import iconAnfi from "../../../images/Menu/Campus/Campus_anfiteatro_icon.svg";
import picAnfi from "../../../images/Menu/Campus/Campus_anfiteatro.jpg";
import iconFo from "../../../images/Menu/Campus/Campus_food_truck.svg";
import picFo from "../../../images/Menu/Campus/Campus_food truck plaza.jpg";
import iconBqq from "../../../images/Menu/Campus/Campus_bbq_plaza_icon.svg";
import picBqq from "../../../images/Menu/Campus/Campus_bbq plaza.jpg";
import iconCourt from "../../../images/Menu/Campus/Campus_food_court_icon.svg";
import picCourt from "../../../images/Menu/Campus/Campus_food court.jpg";

import "./CampusMenu.css";
import Icon from "../../../components/footer/Icon";
import { CampusUltraI } from "./campusScreens/CampusUltraI";
import { CampusUltraLag } from "./campusScreens/CampusUltraLag";
import { CampusUltraEsca } from "./campusScreens/CampusUltraEsca";
import { CampusUltraII } from "./campusScreens/CampusUltraII";
import {
  CampusActionType,
  campusMenuReducer,
} from "../../../reducer/CampusReducer";
import { usecustomcontext } from "../../../reducer/CustomContext";
import { useTranslation } from "react-i18next";

const UP1 = "UPI";
const UP2 = "UPII";
const UPLag = "UPLag";
const UPEsc = "UPEsc";

export const CampusMenu: React.FC<{}> = (props: any) => {
  const [wasCampusSelected2, setWasCampusSelected] = useState<boolean>(false);
  const [campusSelected, setCampusSelected] = useState<string>();

  const { state, dispatch } = usecustomcontext();
  const { t: translate } = useTranslation();

  const onCampusHandle = useCallback((event: any, campusSelected: string) => {
    dispatch({
      type: CampusActionType.CHANGESUBOPTION,
      payload: campusSelected,
    });
    // setCampusSelected(campusSelected);
    setWasCampusSelected(true);
  }, []);

  const wasCampusSelected = state.subMenuSelection !== undefined;

  return (
    <>
      <section>
        <section className="campus_container">
          <div className="campus_fondo"></div>
          <motion.div
            className="campus_animated"
            // style={{ outlineOffset: miScale, outlineWidth: miScale2 }}
            whileHover={{
              transition: { duration: 0.3 },
            }}
          ></motion.div>

          <section className="campus-section">
            <h2 className="campus-title">
              {translate("campus-menu.campus-title")}
            </h2>
            <div className="campus-container">
              <div className="campus-item campus-upi">
                <img src={picUltra1} alt="Campus 1" className="campus-image" />
                <div className="campus_texto">
                  <div className="campus-subtitle-container">
                    <h1 className="campus-subtitle">ULTRAPARK l </h1>
                    <h1 className="campus-subtitle-date"> - 1989</h1>
                  </div>
                  <motion.button
                    whileHover={{
                      scale: 1.2,
                      transition: { duration: 0.2 },
                    }}
                    whileTap={{ scale: 0.9 }}
                    className="campusButton"
                    onClick={(event: any) => {
                      onCampusHandle(event, UP1);
                    }}
                  >
                    {translate("home-screen.project-button")}
                  </motion.button>
                </div>
              </div>
              <div className="campus-item">
                <img
                  src={picUltraLag}
                  alt="Campus 1"
                  className="campus-image"
                />
                <div>
                  <div className="campus-subtitle-container">
                    <h1 className="campus-subtitle">ULTRAPARK LAG </h1>
                    <h1 className="campus-subtitle-date">- 2006</h1>
                  </div>
                  <motion.button
                    whileHover={{
                      scale: 1.2,
                      transition: { duration: 0.2 },
                    }}
                    whileTap={{ scale: 0.9 }}
                    className="campusButton"
                    onClick={(event: any) => {
                      onCampusHandle(event, UPLag);
                    }}
                  >
                    {translate("home-screen.project-button")}
                  </motion.button>
                </div>
              </div>
              <div className="campus-item">
                <img src={picUltra2} alt="Campus 1" className="campus-image" />
                <div>
                  <div className="campus-subtitle-container">
                    <h1 className="campus-subtitle">ULTRAPARK II </h1>
                    <h1 className="campus-subtitle-date">- 2008</h1>
                  </div>
                  <motion.button
                    whileHover={{
                      scale: 1.2,
                      transition: { duration: 0.2 },
                    }}
                    whileTap={{ scale: 0.9 }}
                    className="campusButton"
                    onClick={(event: any) => {
                      onCampusHandle(event, UP2);
                    }}
                  >
                    {translate("home-screen.project-button")}
                  </motion.button>
                </div>
              </div>
              <div className="campus-item campus-esca">
                <img
                  src={picUltraEsca}
                  alt="Campus 1"
                  className="campus-image"
                />
                <div>
                  <div className="campus-subtitle-container">
                    <h1 className="campus-subtitle">ULTRAPARK ESCAZÚ</h1>
                    <h1 className="campus-subtitle-date">- 2018</h1>
                  </div>

                  <motion.button
                    whileHover={{
                      scale: 1.2,
                      transition: { duration: 0.2 },
                    }}
                    whileTap={{ scale: 0.9 }}
                    className="campusButton"
                    onClick={(event: any) => {
                      onCampusHandle(event, UPEsc);
                    }}
                  >
                    {translate("home-screen.project-button")}
                  </motion.button>
                </div>
              </div>
            </div>
          </section>
          <section>
            <h2 className="amenidades">
              {translate("campus-menu.campus-ame-title")}
            </h2>
            <div className="containerAmenidades">
              <div className="column1">
                <div className="campusMedia">
                  <div className="campusMedia-wrapper">
                    <Icon
                      defaultIcon={iconWell}
                      hoverIcon={iconWell}
                      iconwidth="80px"
                      iconheight="80px"
                    />
                  </div>
                  <div className="text">
                    <h2>{translate("campus-menu.campus-wellness-title")}</h2>
                    <p>{translate("campus-menu.campus-wellness-parag-1")}</p>
                    <p>{translate("campus-menu.campus-wellness-parag-2")}</p>
                  </div>
                </div>
              </div>

              <div className="column2">
                <img src={picWell} alt="Imagen de ejemplo" />
              </div>
            </div>
            <div className="containerAmenidades">
              <div className="column2">
                <img className="imgAll" src={picall} alt="Imagen de ejemplo" />
              </div>
              <div className="column1">
                <div className="campusMedia">
                  <div className="campusMedia-wrapper">
                    <Icon
                      defaultIcon={iconall}
                      hoverIcon={iconall}
                      iconwidth="80px"
                      iconheight="80px"
                    />
                  </div>
                  <div className="text">
                    <h2>{translate("campus-menu.campus-all-title")}</h2>
                    <p>{translate("campus-menu.campus-all-parag-1")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="containerAmenidades">
              <div className="column1">
                <div className="campusMedia">
                  <div className="campusMedia-wrapper">
                    <Icon
                      defaultIcon={iconazo}
                      hoverIcon={iconazo}
                      iconwidth="80px"
                      iconheight="80px"
                    />
                  </div>
                  <div className="text">
                    <h2>{translate("campus-menu.campus-azo-title")}</h2>
                    <p>{translate("campus-menu.campus-azo-parag-1")}</p>
                  </div>
                </div>
              </div>

              <div className="column2">
                <img src={picazo} alt="Imagen de ejemplo" />
              </div>
            </div>

            <div className="containerAmenidades">
              <div className="column2">
                <img src={picAnfi} alt="Imagen de ejemplo" />
              </div>
              <div className="column1">
                <div className="campusMedia">
                  <div className="campusMedia-wrapper">
                    <Icon
                      defaultIcon={iconAnfi}
                      hoverIcon={iconAnfi}
                      iconwidth="80px"
                      iconheight="80px"
                    />
                  </div>
                  <div className="text">
                    <h2>{translate("campus-menu.campus-anfi-title")}</h2>
                    <p>{translate("campus-menu.campus-anfi-parag-1")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="containerAmenidades">
              <div className="column1">
                <div className="campusMedia">
                  <div className="campusMedia-wrapper">
                    <Icon
                      defaultIcon={iconFo}
                      hoverIcon={iconFo}
                      iconwidth="80px"
                      iconheight="80px"
                    />
                  </div>
                  <div className="text">
                    <h2>{translate("campus-menu.campus-truck-title")}</h2>
                    <p>{translate("campus-menu.campus-truck-parag-1")}</p>
                  </div>
                </div>
              </div>

              <div className="column2">
                <img src={picFo} alt="Imagen de ejemplo" />
              </div>
            </div>

            <div className="containerAmenidades">
              <div className="column2">
                <img src={picBqq} alt="Imagen de ejemplo" />
              </div>
              <div className="column1">
                <div className="campusMedia">
                  <div className="campusMedia-wrapper">
                    <Icon
                      defaultIcon={iconBqq}
                      hoverIcon={iconBqq}
                      iconwidth="80px"
                      iconheight="80px"
                    />
                  </div>
                  <div className="text">
                    <h2>{translate("campus-menu.campus-bbq-title")}</h2>
                    <p>{translate("campus-menu.campus-bbq-parag-1")}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="containerAmenidades">
              <div className="column1">
                <div className="campusMedia">
                  <div className="campusMedia-wrapper">
                    <Icon
                      defaultIcon={iconCourt}
                      hoverIcon={iconCourt}
                      iconwidth="80px"
                      iconheight="80px"
                    />
                  </div>
                  <div className="text">
                    <h2>{translate("campus-menu.campus-food-title")}</h2>
                    <p>{translate("campus-menu.campus-food-parag-1")}</p>
                  </div>
                </div>
              </div>

              <div className="column2">
                <img src={picCourt} alt="Imagen de ejemplo" />
              </div>
            </div>
          </section>
        </section>
        {/* //This is not the best way to hide and show the dialog. */}
        {/* <AnimatePresence> */}
        {wasCampusSelected === true &&
          state.subMenuSelection?.includes(UP1) && (
            <CampusUltraI
              setWasCampusSelected={setWasCampusSelected}
              setCampusSelected={setCampusSelected}
            ></CampusUltraI>
          )}

        {wasCampusSelected === true &&
          state.subMenuSelection?.includes(UP1) && (
            <CampusUltraI
              setWasCampusSelected={setWasCampusSelected}
              setCampusSelected={setCampusSelected}
            ></CampusUltraI>
          )}

        {wasCampusSelected === true &&
          state.subMenuSelection?.includes(UP2) && (
            <CampusUltraII
              setWasCampusSelected={setWasCampusSelected}
              setCampusSelected={setCampusSelected}
            ></CampusUltraII>
          )}

        {wasCampusSelected === true &&
          state.subMenuSelection?.includes(UPEsc) && (
            <CampusUltraEsca
              setWasCampusSelected={setWasCampusSelected}
              setCampusSelected={setCampusSelected}
            ></CampusUltraEsca>
          )}

        {wasCampusSelected === true &&
          state.subMenuSelection?.includes(UPLag) && (
            <CampusUltraLag
              setWasCampusSelected={setWasCampusSelected}
              setCampusSelected={setCampusSelected}
            ></CampusUltraLag>
          )}
        {/* </AnimatePresence> */}
      </section>
    </>
  );
};
