import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import {
  AnimatePresence,
  motion,
  MotionValue,
  useScroll,
  useSpring,
  useTransform,
  useViewportScroll,
} from "framer-motion";

import campusDialogHero from "../../../../images/Menu/Campus/UPI/Campus_Screen_UPI_Hero.jpg";
import Fondo from "../../../../images/Menu/Campus/Lag/Campus_Screen_UPLag_Fondo.jpg";
import imagen1 from "../../../../images/Menu/Campus/Lag/Campus_Screen_UPLag_Imagen1.jpg";
import imagen2 from "../../../../images/Menu/Campus/Lag/Campus_Screen_UPLag_Imagen2.jpg";
import imagen3 from "../../../../images/Menu/Campus/Lag/Campus_Screen_UPLag_Imagen3.jpg";
import imagen4 from "../../../../images/Menu/Campus/Lag/Campus_Screen_UPLag_Imagen4.jpg";
import imagen5 from "../../../../images/Menu/Campus/Lag/Campus_Screen_UPLag_Imagen5.jpg";
import imagen6 from "../../../../images/Menu/Campus/Lag/Campus_Screen_UPLag_Imagen6.jpg";
import imagen7 from "../../../../images/Menu/Campus/Lag/Campus_Screen_UPLag_Imagen7.jpg";
import imagen8 from "../../../../images/Menu/Campus/Lag/Campus_Screen_UPLag_Imagen8.jpg";

import videoUpLag from "../../../../images/Menu/Campus/Videos/UltraparkLag.mp4";

import "./CampusUltraLag.css";
import { FaTimes } from "react-icons/fa";
import { usecustomcontext } from "../../../../reducer/CustomContext";
import { CampusActionType } from "../../../../reducer/CampusReducer";
import Player from "../../../../components/player/Player";
import { useTranslation } from "react-i18next";

export interface ICampusScreen {
  setWasCampusSelected?: Function;
  setCampusSelected?: Function;
}
export const CampusUltraLag: React.FC<ICampusScreen> = (
  props: ICampusScreen
) => {
  const { setWasCampusSelected, setCampusSelected } = props;
  const { dispatch } = usecustomcontext();

  const { t: translate } = useTranslation();

  const handleClick = useCallback(() => {
    setWasCampusSelected && setWasCampusSelected(false);
    setCampusSelected && setCampusSelected(undefined);
    dispatch({ type: CampusActionType.CHANGESUBOPTION, payload: undefined });
  }, []);

  return (
    <>
      <section className="campusScreenOverlay">
        <AnimatePresence presenceAffectsLayout>
          <motion.div
            className="dialogClose"
            onClick={handleClick}
            exit={{ opacity: 0, transition: { duration: 0.5 } }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            whileHover={{ scale: 1.2, transition: { duration: 0.3 } }}
          >
            <FaTimes size={35} />

            {/* <FaBars size={35} style={{ color: "white" }} /> */}
          </motion.div>
        </AnimatePresence>
        <section className="campusScreenDialogContainer">
          <section className="campusScreenDialogHero">
            <Player url={videoUpLag} />
            <h2 className="video-title-lag">Ultrapark LAG</h2>
          </section>
          <section className="campusScreenContent">
            <div className="campusScreen_Fondo">
              <img src={Fondo} alt="" />
            </div>

            <div className="campusScreenColum">
              <div className="campusScreenColumUno">
                <img src={imagen1} alt="" />
              </div>
              <div className="campusScreenColumDos">
                <img src={imagen2} alt="" />
              </div>
            </div>
            <div className="campusScreenColum">
              <div className="campusScreenColumUno">
                <img src={imagen3} alt="" />
              </div>
              <div className="campusScreenColumDos">
                <img src={imagen4} alt="" />
              </div>
            </div>
            <div className="campusScreen_Completa">
              <img src={imagen5} alt="" />
            </div>

            <div className="campusScreenColum">
              <div className="campusScreenColumUno">
                <img src={imagen6} alt="" />
              </div>
              <div className="campusScreenColumDos">
                <img src={imagen7} alt="" />
              </div>
            </div>
            <div className="campusScreenColum campus-screen-last">
              <div className="campusScreenColumUnoUbi">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3929.6065958402633!2d-84.116277!3d9.966652!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa0fb39453d621b%3A0x4c05be05696aad4c!2sZona%20Franca%20Ultralag!5e0!3m2!1sen!2scr!4v1698896160917!5m2!1sen!2scr"
                  width="100%"
                  height="450"
                  style={{ border: "0" }}
                  loading="lazy"
                ></iframe>
              </div>
              <div className="campusScreenColumDos address">
                <h2>{translate("campus-menu.campus-location")}</h2>
                <p>Zona Franca Ultralag, Heredia Province, Heredia</p>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  );
};
