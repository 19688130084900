import { AnimatePresence, motion } from "framer-motion";
import flechaIcon from "../../../images/Menu/Flechabajar.svg";
import "./CampusMenuDialog.css";
import Footer from "../../../components/footer/Footer";
import NavbarAnimated from "../../../components/navbar/NavbarAnimated";
import OneStopShopMenu from "../oneStopShop/OneStopShopMenu";
import { useTranslation } from "react-i18next";
import { CampusMenu } from "./CampusMenu";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { FaBars } from "react-icons/fa";
import logoBlack from "../../../images/Menu/LogoUltraparkBlack.png";

export default function CampusMenuDialog() {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AnimatePresence>
      <motion.div className="campus-dialog-container">
        <a href="/" className="ultra-dialog-logo">
          <img src={logoBlack} alt="logoBlack" />
        </a>
        <motion.button
          className="close-dialog"
          onClick={() => {
            navigate("/", { state: { openMenu: true } });
          }}
          whileHover={{
            scale: 1.2,
            transition: { duration: 0.1 },
          }}
          exit={{ opacity: 0 }}
          whileTap={{ scale: 0.9 }}
        >
          <svg className="icon icon--arrowback" width={"50px"} height={"35px"}>
            <FaBars
              size={30}
              style={{
                color: "black",
                top: "14px",
                margin: "0 30px 0 0",
              }}
            />
          </svg>
        </motion.button>
        {/* <NavbarAnimated
            setIsMenuOpen={() => {}}
            isMenuOpen={true}
            onClickHandled={() => {
              navigate("/", { state: { openMenu: true } });
            }}
          /> */}
        <div className="campus-dialog-container-title">
          <h2 className="campus-dialog-title">
            {translate("options.campus-option")}
          </h2>
          <motion.div
            className="menuFlechaDialog"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.1 } }}
          >
            <img src={flechaIcon} alt="flecha" />
          </motion.div>
        </div>
        <CampusMenu />
        <Footer />
      </motion.div>
    </AnimatePresence>
  );
}
