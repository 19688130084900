import React, { useState } from "react";

type IconProps = {
  defaultIcon: string;
  hoverIcon: string;
  iconwidth?: string;
  iconheight?: string;
};

const Icon: React.FC<IconProps> = ({
  defaultIcon,
  hoverIcon,
  iconwidth,
  iconheight,
}) => {
  const [iconSrc, setIconSrc] = useState(defaultIcon);

  return (
    <div
      style={{
        backgroundImage: `url(${iconSrc})`,
        backgroundRepeat: "no-repeat",
        width: iconwidth ? iconwidth : "50px",
        height: iconheight ? iconheight : "50px",
      }}
      // onMouseOver={() => setIconSrc(hoverIcon)}
      // onMouseOut={() => setIconSrc(defaultIcon)}
    />
  );
};

export default Icon;
