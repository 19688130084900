import {
  AnimatePresence,
  motion,
  useScroll,
  useTransform,
} from "framer-motion";
// import OneStopShopMain from "./oneStopShop/main/OneStopShopMain";
// import UltraparkTeam from "./ultraparkTeam/ultraparkTeam";
import WhyUS from "./whyUs/WhyUs";
// import ProjectsHorizontalScroll from "./projectHorizontalScroll/ProjectsHorizontalScroll";

// import HeroPage from "./hero/HeroPage";
// import Footer from "../components/footer/Footer";
// import Clients from "./clients/Clients";

import PreFooter from "../components/preFooter/PreFooter";
import React, { Suspense } from "react";
import FooterAnimation from "../components/footer/FooterAnimation";

const Footer = React.lazy(() => import("../components/footer/Footer"));
const HeroPage = React.lazy(() => import("./hero/HeroPage"));
const Clients = React.lazy(() => import("./clients/Clients"));

const UltraparkTeam = React.lazy(() => import("./ultraparkTeam/ultraparkTeam"));
const OneStopShopMain = React.lazy(
  () => import("./oneStopShop/main/OneStopShopMain")
);
const ProjectsHorizontalScroll = React.lazy(
  () => import("./projectHorizontalScroll/ProjectsHorizontalScroll")
);

// const slideData: ISlide[] = [
//   {
//     id: 1,
//     title: "Ultrapark I",
//     text: "Subtitle for Card 1",
//     imageUrl: "UP1.png",
//   },
//   {
//     id: 2,
//     title: "Ultrapark II",
//     text: "Subtitle for Card 2",
//     imageUrl: "UP2.png",
//   },
//   {
//     id: 3,
//     title: "Ultrapark Lag",
//     text: "Subtitle for Card 3",
//     imageUrl: "UPLag.png",
//   },
//   {
//     id: 4,
//     title: "Ultrapark Escazú",
//     text: "Subtitle for Card 4",
//     imageUrl: "UPEsc.png",
//   },
// ];
export interface HomePageScreenProps {
  setIsMenuOpen?: Function;
  setMenuOptionParam?: Function;
}

const HomePageScreen = ({
  setIsMenuOpen,
  setMenuOptionParam,
}: HomePageScreenProps) => {
  const { scrollYProgress } = useScroll();
  // const y1 = useTransform(scrollYProgress, [0, 0.5, 1], [0, 50, 100]); // source, [initial value, lmite value(threahold, final value)],  the relationshi between input and output REMEMBER
  const x1 = useTransform(scrollYProgress, [0.1, 0.3], [0, -1900 * 1.1]); // source, [initial value, lmite value(threahold, final value)],  the relationshi between input and output REMEMBER

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        exit={{ opacity: 0, transition: { duration: 0.1 } }}
        style={{
          overflowY: "hidden",
        }}
      >
        {/* <div
      style={
        {
          // overflowY: "hidden",
        }
      }
    > */}
        <Suspense fallback={<div>Loading...</div>}>
          <HeroPage
            setMenuOptionParam={setMenuOptionParam}
            setIsMenuOpen={setIsMenuOpen}
          />
          <OneStopShopMain
            setMenuOptionParam={setMenuOptionParam}
            setIsMenuOpen={setIsMenuOpen}
          />
          <UltraparkTeam
            setMenuOptionParam={setMenuOptionParam}
            setIsMenuOpen={setIsMenuOpen}
          />
          <WhyUS />
          <ProjectsHorizontalScroll
            setMenuOptionParam={setMenuOptionParam}
            setIsMenuOpen={setIsMenuOpen}
          />
          {<Clients />}
          {<PreFooter />}
          {
            <Footer
              setIsMenuOpen={setIsMenuOpen}
              setMenuOptionParam={setMenuOptionParam}
            />
          }
        </Suspense>
      </motion.div>
    </AnimatePresence>
  );
};

export default HomePageScreen;
