import { useTranslation } from "react-i18next";
import { HomePageScreenProps } from "../../modules/HomePageScreen";
import { MobileWidth } from "../../utils/Settings";
import { useWindowWidth } from "../../utils/customHook/useWindowWidth";
import "./Footer.css";
import Icon from "./Icon";
import { Link, useNavigate } from "react-router-dom";

const ULTRA_MENU = "ULTRA";
const SOS_MENU = "SOS";
const CAMPUS_MENU = "CAMPUS";
const ONESTOP_MENU = "ONE";
const CR_MENU = "COSTA";
const Footer = ({ setIsMenuOpen, setMenuOptionParam }: HomePageScreenProps) => {
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();

  const isMobile = windowWidth < MobileWidth;
  const onMenuOption = (optionselected?: string) => {
    setIsMenuOpen && setIsMenuOpen(true);
    setMenuOptionParam && setMenuOptionParam(optionselected);
  };

  const { i18n, t: translate } = useTranslation();

  function changeLanguage(e: any) {
    i18n.changeLanguage(e.target.value);
    navigate("/", { state: { openMenu: false } });
  }

  return (
    <section>
      <div className="footer">
        <div className="footercontainer">
          {!isMobile && (
            <div className="footer-menu">
              <p>{translate("footer.menu")}</p>
              <ul>
                <li className="nav-item">
                  <a
                    onClick={() => {
                      navigate("/ultrapark");
                    }}
                  >
                    Ultrapark
                  </a>
                </li>
                <li className="nav-item">
                  <Link to={"/one-stop-shop"}>One Stop Shop</Link>
                  {/* <a href="/one-stop-shop">One Stop Shop</a> */}
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => {
                      navigate("/campus");
                    }}
                  >
                    {translate("options.campus-option")}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => {
                      navigate("/sos");
                    }}
                  >
                    {translate("options.sos-option")}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    onClick={() => {
                      navigate("/costarica");
                    }}
                  >
                    Costa Rica
                  </a>
                </li>
              </ul>
            </div>
          )}
          <div className="getInTouch">
            <p>{translate("footer.contact-us")}</p>
            <div className="getInTouch-wrapper">
              <div className="getInTouch-content">
                <p className="title">{translate("footer.office")}</p>
                <p className="contentInfo">(+506) 2293 3333</p>
              </div>
              <div className="getInTouch-content">
                <p className="title">Email</p>
                <p className="contentInfo">info@ultrapark.com</p>
              </div>
              <div className="getInTouch-content">
                <p className="title">
                  {translate("campus-menu.campus-location")}
                </p>
                <p className="contentInfo">
                  San Rafael de Escazú,
                  <br /> San José. Costa Rica.
                </p>
              </div>
            </div>
          </div>

          <div className="socialMedia">
            <div className="botonesFooter">
              <p>{translate("footer.idioma")}</p>
              <div className="butonesTranslate">
                <button
                  style={{
                    marginRight: "24px",
                    backgroundColor: i18n.language.includes("es")
                      ? "black"
                      : "white",
                    color: i18n.language.includes("es") ? "white" : "black",
                  }}
                  onClick={changeLanguage}
                  value={"es"}
                >
                  Es
                </button>
                <button
                  style={{
                    backgroundColor: i18n.language.includes("en")
                      ? "black"
                      : "white",
                    color: i18n.language.includes("en") ? "white" : "black",
                  }}
                  onClick={changeLanguage}
                  value={"en"}
                >
                  En
                </button>
              </div>
            </div>
            <div className="socialMedia-wrapper">
              <a
                className="bolsa-empleo-nav-link"
                href="https://docs.google.com/forms/d/e/1FAIpQLScpxHDrM2OxIhNklXq0nRE4U5XSR8u0vzA065U--2-mIfbiAA/viewform"
                target="_blank"
                rel="noreferrer"
              >
                {translate("footer.job")}
              </a>

              <Icon
                defaultIcon="./img/footer/Instagram.svg"
                hoverIcon="./img/footer/HoverInstagram.svg"
              />
              <Icon
                defaultIcon="./img/footer/Linkedin.svg"
                hoverIcon="./img/footer/HoverLinkedin.svg"
              />
              <Icon
                defaultIcon="./img/footer/Facebook.svg"
                hoverIcon="./img/footer/HoverFacebook.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
