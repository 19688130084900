import React, { useEffect, useState } from "react";
import "./App.css";
import NavbarAnimated from "./components/navbar/NavbarAnimated";

import { GradientMenuAnimated } from "./components/animations/gradientAnimation/GradientMenuAnimated";
import HomePageScreen from "./modules/HomePageScreen";
import { useLocation } from "react-router-dom";
import { use } from "i18next";

function AppMain() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [wasSubMenuClicked, setWasSubMenuClicked] = useState(false);
  const [menuOptionParam, setMenuOptionParam] = useState<string>();
  const location = useLocation();

  useEffect(() => {
    if (location?.state && location.state?.openMenu) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
      setWasSubMenuClicked(false);
    }
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <NavbarAnimated
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        wasSubMenuClicked={wasSubMenuClicked}
      />

      {isMenuOpen ? (
        <GradientMenuAnimated
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          subMenuWasClicked={setWasSubMenuClicked}
          subMenuParamOption={menuOptionParam}
          setMenuOptionParam={setMenuOptionParam}
        />
      ) : (
        <HomePageScreen
          setIsMenuOpen={setIsMenuOpen}
          setMenuOptionParam={setMenuOptionParam}
        />
      )}
      {/* <CampusScreen></CampusScreen> */}
    </div>
  );
}

export default AppMain;
