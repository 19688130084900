import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import {
  motion,
  MotionValue,
  useScroll,
  useSpring,
  useTransform,
  useViewportScroll,
} from "framer-motion";
import MisionIcon from "../../../images/sections/ultrapark/iconoMision.svg";
import VisionIcon from "../../../images/sections/ultrapark/visionIcon.svg";
import "./SOSMenu.css";

import bgImage from "../../../images/Menu/sos/Sos_Hero.jpg";
import ambiente from "../../../images/Menu/sos/Soste_ambiente.jpg";
import icon_ambiente from "../../../images/Menu/sos/Soste_icon_ambiente.svg";
import responsabilidad from "../../../images/Menu/sos/Soste_responsabilidad.jpg";
import icon_responsabilidad from "../../../images/Menu/sos/Soste_icon_responsabilidad.svg";
import innovacion from "../../../images/Menu/sos/Soste_innovacion.jpg";
import icon_innovacion from "../../../images/Menu/sos/Soste_icon_innovacion.svg";
import { useTranslation } from "react-i18next";

export default function SOSMenu() {
  const { t: translate } = useTranslation();

  return (
    <>
      <section>
        <section className="sosteni_container">
          <div className="sosteni_fondo"></div>
          <motion.div
            className="sosteni_animated"
            // style={{ outlineOffset: miScale, outlineWidth: miScale2 }}
            whileHover={{
              transition: { duration: 0.3 },
            }}
          ></motion.div>
        </section>

        <section className="soste_content">
          <div className="ambiente_container">
            <div className="ambienteCol">
              <div className="imagenAmbiente">
                <img src={ambiente} alt="" />
              </div>
              <div className="textoAmbiente">
                <div className="iconAmbiente">
                  <div className="icono">
                    <img src={icon_ambiente} alt="" />
                  </div>
                  <h1>{translate("sos-menu.sos-amb-title")}</h1>
                </div>
                <div className="parrafoAmbiente">
                  <p>{translate("sos-menu.sos-amb-parag-1")}</p>
                  <p>{translate("sos-menu.sos-amb-parag-2")}</p>
                  <p>{translate("sos-menu.sos-amb-parag-3")}</p>
                  <p>{translate("sos-menu.sos-amb-parag-4")}</p>
                  <p>{translate("sos-menu.sos-amb-parag-extra-1")}</p>
                  <p>{translate("sos-menu.sos-amb-parag-extra-2")}</p>
                  <p>{translate("sos-menu.sos-amb-parag-extra-3")}</p>
                  <p>{translate("sos-menu.sos-amb-parag-extra-4")}</p>
                  <p>{translate("sos-menu.sos-amb-parag-extra-5")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="responsabilidad_container">
            <div className="ambienteCol">
              <div className="imagenAmbiente">
                <img src={responsabilidad} alt="" />
              </div>
              <div className="textoAmbiente">
                <div className="iconAmbiente">
                  <div className="icono">
                    <img src={icon_responsabilidad} alt="" />
                  </div>
                  <h1>{translate("sos-menu.sos-resp-title")}</h1>
                </div>
                <div className="parrafoAmbiente">
                  <p>{translate("sos-menu.sos-resp-parag-1")}</p>
                  <p>{translate("sos-menu.sos-resp-parag-2")}</p>
                  <p>{translate("sos-menu.sos-resp-parag-3")}</p>
                  <p>{translate("sos-menu.sos-resp-parag-4")}</p>
                  <p>{translate("sos-menu.sos-resp-parag-extra-1")}</p>
                  <p>{translate("sos-menu.sos-resp-parag-extra-2")}</p>
                  <p>{translate("sos-menu.sos-resp-parag-extra-3")}</p>
                  <p>{translate("sos-menu.sos-resp-parag-extra-4")}</p>
                  <p>{translate("sos-menu.sos-resp-parag-extra-5")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="innovacion_container">
            <div className="ambienteCol">
              <div className="imagenAmbiente">
                <img src={innovacion} alt="" />
              </div>
              <div className="textoAmbiente">
                <div className="iconAmbiente">
                  <div className="icono">
                    <img src={icon_innovacion} alt="" />
                  </div>
                  <h1>{translate("sos-menu.sos-inno-title")}</h1>
                </div>
                <div className="parrafoAmbiente">
                  <p>{translate("sos-menu.sos-inno-parag-1")}</p>
                  <p>{translate("sos-menu.sos-inno-parag-2")}</p>
                  <p>{translate("sos-menu.sos-inno-parag-3")}</p>
                  <p>{translate("sos-menu.sos-inno-parag-extra-1")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
